/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */
import React, { useContext, useEffect, useState } from "react";
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"
import Lottie from "lottie-react";
import gsap from "gsap";
import Flip from "gsap/Flip";
import SplitType from 'split-type';
import { ScrollTrigger } from "gsap/ScrollTrigger";

// Section import
import Header from "./header"
import Footer from "./footer"

// Style import
import "../sass/style.scss"

import Prelaoder from "../Context/peloader";
import DecojentPreloader from "../assets/DecojentPreloader.json"





//ContextApi
import { GlobalDispatchContext, GlobalStateContext } from '../Context/peloader';

const Layout = (props) => {
  let unmounted = false;

  const state = useContext(GlobalStateContext);
  const [loader, setLoader] =  useState(true);
  useEffect(() => {
    window.onbeforeunload = function () {
      window.scrollTo(0,0);
    }

    gsap.registerPlugin(Flip,ScrollTrigger)

    


    // const lenis = new Lenis({
    //   duration: 1.2,
    //   easing: (t) => Math.min(1, 1.001 - Math.pow(2, -10 * t)), // https://www.desmos.com/calculator/brs54l4xou
    //   direction: 'vertical', // vertical, horizontal
    //   gestureDirection: 'vertical', // vertical, horizontal, both
    //   smooth: true,
    //   mouseMultiplier: .6,
    //   smoothTouch: false,
    //   touchMultiplier: 1,
    //   infinite: false,
    // })
    
    // function raf(time) {
    //   lenis.raf(time)
    //   requestAnimationFrame(raf)
    // }
    
    // requestAnimationFrame(raf)

    if(!unmounted){ 
      gsap.set(".cursor", {xPercent: -50, yPercent: -50});
      const cursorTag = document.querySelector(".cursor");
      const text = cursorTag.querySelector("div span");
      // const video = cursorTag.querySelector(".video");
      // const productdesign = cursorTag.querySelector(".video .productdesign");
      // const webdesign = cursorTag.querySelector(".video .webdesign");
      // const illustration = cursorTag.querySelector(".video .illustration");
      // const animation = cursorTag.querySelector(".video .animation");
      // const branding = cursorTag.querySelector(".video .branding");
      // const development = cursorTag.querySelector(".video .development");

      const cards = document.querySelectorAll(".showreel[data-hover]")
      const projects = document.querySelectorAll(".page-wrapper .view-case-study[data-hover]");
      const navLinks = document.querySelectorAll(".link-hover");
      const services = document.querySelectorAll(".services-section .service-items .extra-large");
      const pos = { x: window.innerWidth / 2, y: window.innerHeight / 2 };
      const mouse = { x: pos.x, y: pos.y };
      const speed = 0.2;

      const xSet = gsap.quickSetter(cursorTag, "x", "px");
      const ySet = gsap.quickSetter(cursorTag, "y", "px");

      window.addEventListener("mousemove", e => {    
        mouse.x = e.x;
        mouse.y = e.y;
      });

      

      projects.forEach(project => {
        if(project.getAttribute("href") === "/work/xaqt"){
          project.addEventListener("mouseover",()=>{
            console.log("hover")
            text.innerHTML = project.getAttribute("data-hover") 
            gsap.to(cursorTag, {height:"130px", width:"130px", duration: .4, opacity: .8, backgroundColor: "#E4777E",});
            gsap.to(text,  {opacity:1, duration: .2, textAlign: "center"});
          })
          project.addEventListener("mouseleave",()=>{
              gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, backgroundColor: "transparent"});
              gsap.to(text, {opacity:0, duration: .2},'<');
          })
        }
        if(project.getAttribute("href") === "/work/tiltit"){
          project.addEventListener("mouseover",()=>{
            text.innerHTML = project.getAttribute("data-hover") 
            gsap.to(cursorTag, {height:"130px", width:"130px", duration: .4, opacity: .8, backgroundColor: "#FF7C64"});
            gsap.to(text,  {opacity:1, duration: .2, textAlign: "center"});
          })
          project.addEventListener("mouseleave",()=>{
              gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, backgroundColor: "transparent"});
              gsap.to(text, {opacity:0, duration: .2},'<');
          })
        }
        if(project.getAttribute("href") === "/work/cookingenie"){
          project.addEventListener("mouseover",()=>{
            text.innerHTML = project.getAttribute("data-hover") 
            gsap.to(cursorTag, {height:"130px", width:"130px", duration: .4, opacity: .8, backgroundColor: "#454F33"});
            gsap.to(text,  {opacity:1, duration: .2, textAlign: "center"});
          })
          project.addEventListener("mouseleave",()=>{
              gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, backgroundColor: "transparent"});
              gsap.to(text, {opacity:0, duration: .2},'<');
          })
        }
        if(project.getAttribute("href") === "/work/unikube"){
          project.addEventListener("mouseover",()=>{
            text.innerHTML = project.getAttribute("data-hover") 
            gsap.to(cursorTag, {height:"130px", width:"130px", duration: .4, opacity: .8, backgroundColor: "#273277"});
            gsap.to(text,  {opacity:1, duration: .2, textAlign: "center"});
          })
          project.addEventListener("mouseleave",()=>{
              gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, backgroundColor: "transparent"});
              gsap.to(text, {opacity:0, duration: .2},'<');
          })
        }
        if(project.getAttribute("href") === "/work/evocalize"){
          project.addEventListener("mouseover",()=>{
            text.innerHTML = project.getAttribute("data-hover") 
            gsap.to(cursorTag, {height:"130px", width:"130px", duration: .4, opacity: .8, backgroundColor: "#3AC6F3"});
            gsap.to(text,  {opacity:1, duration: .2, textAlign: "center"});
          })
          project.addEventListener("mouseleave",()=>{
              gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, backgroundColor: "transparent"});
              gsap.to(text, {opacity:0, duration: .2},'<');
          })
        }
        if(project.getAttribute("href") === "/work/eletive"){
          project.addEventListener("mouseover",()=>{
            text.innerHTML = project.getAttribute("data-hover") 
            gsap.to(cursorTag, {height:"130px", width:"130px", duration: .4, opacity: .8, backgroundColor: "#3F52C2"});
            gsap.to(text,  {opacity:1, duration: .2, textAlign: "center"});
          })
          project.addEventListener("mouseleave",()=>{
              gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, backgroundColor: "transparent"});
              gsap.to(text, {opacity:0, duration: .2},'<');
          })
        }
      })

      cards.forEach(card=>{
        card.addEventListener("mouseover",() => {
            text.innerHTML = card.getAttribute("data-hover") 
            gsap.to(cursorTag, {height:"130px", width:"130px", duration: .4, opacity: .7, backgroundColor: "#1A1733"});
            gsap.to(text,  {opacity:1, duration: .2});
        })
        card.addEventListener("mouseleave",()=>{
            gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, backgroundColor: "transparent"});
            gsap.to(text, {opacity:0, duration: .2},'<');
        })
    })

      // navLinks.forEach(link => {
      //   link.addEventListener("mouseover",()=>{ 
      //       gsap.to(cursorTag, {height:"85px", width:"85px", duration: .4, opacity: .1, backgroundColor: "var(--primary-black)", border: "1px solid black"});
      //   })
      //   link.addEventListener("mouseleave",()=>{
      //       gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, opacity: .7, border: "none",});
      //   })
      // })

      // services.forEach(service => {
      //   if(service.innerHTML === "Product Design / SaaS UX Design"){
      //     service.addEventListener("mouseover", (e) => {
      //       gsap.to(cursorTag, {height:"142px", width:"142px", duration: .4, opacity: 1, backgroundColor: "white", border: "none",mixBlendMode: "overlay",})
      //       gsap.to(video, {width: "142px", height: "142px", duration:.4})
      //       gsap.to(productdesign, {opacity: 1, duration: .2})
            
      //       // const hero = document.querySelector('[data-hero]')
      //       // const { clientX, clientY } = e
      //       // const x = Math.round((clientX / window.innerWidth) * 100)
      //       // const y = Math.round((clientY / window.innerHeight) * 100)
      //   	  // hero.style.setProperty('--x', `${x}%`)
      //   	  // hero.style.setProperty('--x', `${y}%`)
      //     })
      //     service.addEventListener("mouseleave",()=>{
      //       gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, opacity: .7, border: "none", backgroundColor: "transparent", mixBlendMode: "unset"});
      //       gsap.to(video, {width: "0px", height: "0px", duration:.4})
      //       gsap.to(productdesign, {opacity: 0, duration: .2})
      //     })
      //   }
      //   if(service.innerHTML === "Website Design / Landing Pages"){
      //     service.addEventListener("mouseover", () => {
      //       gsap.to(cursorTag, {height:"142px", width:"142px", duration: .4, opacity: 1, backgroundColor: "transparent", border: "none", mixBlendMode: "overlay"})
      //       gsap.to(video, {width: "142px", height: "142px", duration:.4})
      //       gsap.to(webdesign, {opacity: 1, duration: .2})
      //     })
      //     service.addEventListener("mouseleave",()=>{
      //       gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, opacity: .7, border: "none", backgroundColor: "transparent",mixBlendMode: "unset"});
      //       gsap.to(video, {width: "0px", height: "0px", duration:.4})
      //       gsap.to(webdesign, {opacity: 0, duration: .2})
      //     })
      //   }
      //   if(service.innerHTML === "Illustration"){
      //     service.addEventListener("mouseover", () => {
      //       gsap.to(cursorTag, {height:"142px", width:"142px", duration: .4, opacity: 1, backgroundColor: "transparent", border: "none",mixBlendMode: "overlay"})
      //       gsap.to(video, {width: "142px", height: "142px", duration:.4})
      //       gsap.to(illustration, {opacity: 1, duration: .2})
      //     })
      //     service.addEventListener("mouseleave",()=>{
      //       gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, opacity: .7, border: "none", backgroundColor: "transparent", mixBlendMode: "unset"});
      //       gsap.to(video, {width: "0px", height: "0px", duration:.4})
      //       gsap.to(illustration, {opacity: 0, duration: .2})
      //     })
      //   }
      //   if(service.innerHTML === "Animations"){
      //     service.addEventListener("mouseover", () => {
      //       gsap.to(cursorTag, {height:"142px", width:"142px", duration: .4, opacity: 1, backgroundColor: "transparent", border: "none", mixBlendMode: "overlay"})
      //       gsap.to(video, {width: "142px", height: "142px", duration:.4})
      //       gsap.to(animation, {opacity: 1, duration: .2})
      //     })
      //     service.addEventListener("mouseleave",()=>{
      //       gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, opacity: .7, border: "none", backgroundColor: "transparent", mixBlendMode: "unset"});
      //       gsap.to(video, {width: "0px", height: "0px", duration:.4})
      //       gsap.to(animation, {opacity: 0, duration: .2})
      //     })
      //   }
      //   if(service.innerHTML === "Branding &amp; Pitch Decks"){
      //     service.addEventListener("mouseover", () => {
      //       gsap.to(cursorTag, {height:"142px", width:"142px", duration: .4, opacity: 1, backgroundColor: "transparent", border: "none", mixBlendMode: "overlay"})
      //       gsap.to(video, {width: "142px", height: "142px", duration:.4})
      //       gsap.to(branding, {opacity: 1, duration: .2})
      //     })
      //     service.addEventListener("mouseleave",()=>{
      //       gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, opacity: .7, border: "none", backgroundColor: "transparent", mixBlendMode: "unset"});
      //       gsap.to(video, {width: "0px", height: "0px", duration:.4})
      //       gsap.to(branding, {opacity: 0, duration: .2})
      //     })
      //   }
      //   if(service.innerHTML === "Web Development"){
      //     service.addEventListener("mouseover", () => {
      //       gsap.to(cursorTag, {height:"142px", width:"142px", duration: .4, opacity: 1, backgroundColor: "transparent", border: "none", mixBlendMode: "overlay"})
      //       gsap.to(video, {width: "142px", height: "142px", duration:.4})
      //       gsap.to(development, {opacity: 1, duration: .2})
      //     })
      //     service.addEventListener("mouseleave",()=>{
      //       gsap.to(cursorTag, {height:"15px", width:"15px", duration: .4, opacity: .7, border: "none", backgroundColor: "transparent", mixBlendMode: "unset"});
      //       gsap.to(video, {width: "0px", height: "0px", duration:.4})
      //       gsap.to(development, {opacity: 0, duration: .2})
      //     })
      //   }
      // })

      gsap.ticker.add(() => {
        // adjust speed for higher refresh monitors
        const dt = 1.0 - Math.pow(1.0 - speed, gsap.ticker.deltaRatio()); 

        pos.x += (mouse.x - pos.x) * dt;
        pos.y += (mouse.y - pos.y) * dt;
        xSet(pos.x);
        ySet(pos.y);
      });

      // Scrollbar.init(document.querySelector('#my-scrollbar'));
        let t1 = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
        t1.to("#pre-loader", 1, {
          height: 0,
          zIndex: 0,
          opacity: 0,
          ease: "Power4.easeInOut",
          delay: 2.1,
          stagger: {
            amount: 0.3
          }
        })
        // let t2 = gsap.timeline({defaults: {ease: "Power4.easeInOut"}});
        // t2.to(".preloader-svg", .6, {
        //   opacity:0,
        //   ease: "Power4.easeInOut",
        //   delay: 2,
        //   stagger: {
        //     amount: 0.1
        //   }
        // })

        //navigation



      /*
        Footer Interactions Begin
      */

        const footerHeading = document.querySelector(".site-footer .footer-title .section-title");

        gsap.set(footerHeading, { autoAlpha: 1 });
        
        const splitFooterHeading = new SplitType(footerHeading, {
          type: "words,lines",
          wordsClass: "words",
          linesClass: "lines",
        })

        const tlFooterHeading = gsap.timeline({
          scrollTrigger:{
            trigger: ".site-footer",
          }
        });
        tlFooterHeading.from(splitFooterHeading.words,{
          // duration: 1,
          duration: .5,
          yPercent: 150,
          ease: "Power4.easeInOut",
          rotationX: 0.5,
          rotationY: 0.5,
          z: 0.5,
          delay: .4,
          stagger: {
            amount: 0.1
          }
        });

        const tlBtnBlock = gsap.timeline({
          scrollTrigger: {
            trigger: ".site-footer",
          }
        })
        tlBtnBlock.to(".site-footer .footer-title .btn-block .primary-btn", .5, {
          y: 0,
          opacity: 1,
          ease: "Power4.easeInOut",
          delay: .5,
        })

        const tlDecojentLogo = gsap.timeline({
          scrollTrigger: {
            trigger: ".site-footer .nav-block",
          }
        })
        tlDecojentLogo.to(".site-footer .nav-block .contact-block .logo", .4, {
          opacity: 1,
          ease: "Power4.easeInOut",
          delay: 0.6,
        })

        const tlHelloText = gsap.timeline({
          scrollTrigger: {
            trigger: ".site-footer .nav-block"
          }
        })
        tlHelloText.to(".site-footer .nav-block .contact-block .hello-text", .4, {
          y: 0,
          opacity: 1,
          ease: "Power4.easeInOut",
          delay: 0.6,
        })

        const tlEmail = gsap.timeline({
          scrollTrigger: {
            trigger: ".site-footer .nav-block"
          }
        })
        tlEmail.to(".site-footer .nav-block .contact-block .small", .4, {
          y: 0,
          opacity: 1,
          ease: "Power4.easeInOut",
          delay: 0.6,
        })

        const tlNavBlock = gsap.timeline({
          scrollTrigger: {
            trigger: ".site-footer .nav-block"
          }
        })
        tlNavBlock.to(".site-footer .nav-block .navigation-block .group ul", .5, {
          x: 0,
          opacity: 1,
          ease: "Power4.easeInOut",
          delay: .5,
        })

        const tlNavBlockOpacity = gsap.timeline({
          scrollTrigger: {
            trigger: ".site-footer .nav-block"
          }
        })
        tlNavBlockOpacity.to(".site-footer .nav-block .navigation-block .group ul", .5, {
          opacity: 1,
          ease: "Power4.easeInOut",
          delay: 0.6,
        })

      /*
        Footer Interactions End
      */
      // setTimeout(() => {
      //   setLoader(true)
      // },3000) 

      // Simulate a delay for loading content
      const timer = setTimeout(() => {
        setLoader(false); // Once content is ready, hide preloader
      }, 3000); // Adjust the delay based on your content loading time

      return () => clearTimeout(timer); // Cleanup the timer
    }
    return () => {
      unmounted = true;
    }
  },[])

  const data = useStaticQuery(graphql`
    query SiteTitleQuery {
      site {
        siteMetadata {
          title
        }
      }
    }
  `)
  return (
  <>
    {loader
       ? <div id="pre-loader">
          {/* <img src={PreLoader} /> */}
          <Lottie 
            animationData={DecojentPreloader} 
            loop={true}
            className="preloader-svg"
          />
          {/* <div className="btn-block interaction">
          <Link to="/contact" className="btn outline-btn" >
              <span className="btn-text">Start a Project</span>
              <i className="btn__icon btn__icon--append icon-arrow-right-up"></i>
            </Link>
          </div> */}
        </div>
       :
      ""
    } 
    {/* <Prelaoder> */}
        <div className="cursor">
          <div>
            <span></span>
          </div>
          {/* <div className="video">
            <video
              src="https://player.vimeo.com/progressive_redirect/playback/808952236/rendition/1080p/file.mp4?loc=external&signature=8317b74ed8a6cb9b0cfb4f081589f6df4682c6463e3ed24d4fa2a17ad300de54" 
              muted 
              autoPlay 
              loop
              className="productdesign"
            >
            </video>
            <video
              src="https://player.vimeo.com/progressive_redirect/playback/808952258/rendition/1080p/file.mp4?loc=external&signature=5b58bc4f53a6c39ad9ec2789e25d645b9ec4a3aab95a5fb5158084ba836cb095" 
              muted 
              autoPlay 
              loop
              className="webdesign"
            >
            </video>
            <video
              src="https://player.vimeo.com/progressive_redirect/playback/808952205/rendition/1080p/file.mp4?loc=external&signature=dffc3975dac2c3bad166c8db98a61e59883e01beb91eebea882a0359d0b0ebfa"
              muted 
              autoPlay 
              loop
              className="illustration"
            >
            </video>
            <video
              src="https://player.vimeo.com/progressive_redirect/playback/808952106/rendition/1080p/file.mp4?loc=external&signature=3dabedc2bd0097d4a7272cabc4692367b98601cf526686d39c93cc8fafbd2105" 
              muted 
              autoPlay 
              loop
              className="animation"
            >
            </video>
            <video
              src="https://player.vimeo.com/progressive_redirect/playback/808952148/rendition/1080p/file.mp4?loc=external&signature=28235a41a42d22aaba7b7fe74e610a74b5c6f6b09e5411027fa163a3fcb4a825" 
              muted 
              autoPlay 
              loop
              className="branding"
            >
            </video>
            <video
              src="https://player.vimeo.com/progressive_redirect/playback/808952174/rendition/1080p/file.mp4?loc=external&signature=2d1f497678afba6c532dff7f84c7b0eb0fc8cf7763f9b5ab954ce13ac5ed8665"
              muted 
              autoPlay 
              loop
              className="development"
            >
            </video>
          </div> */}
        </div>
        <Header siteTitle={data.site.siteMetadata?.title || `Title`} extraClass={props.headerClass}/>
            <div className="page-section">
              <main >{props.children}</main>
            </div>
          <Footer/>
    {/* </Prelaoder> */}
  </>
  )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout;